












































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { connectorMethodsService } from '@/services/ConnectorMethodsService';
import { searchConfigService } from '@/services/SearchConfigService';
import EventBus from '@/EventBus';
import DataTable from '@/components/common/DataTable.vue';

@Component({
  components: { Header, DataTable },
})
export default class SearchConfig extends Vue {
  methods: any = [];
  searchConfigs: any = [];
  searchConfigsOptions: any = [
    { field: 'name', label: 'Nazwa' },
    { field: 'type', label: 'Typ', options: {} },
  ];
  isLoadingSearchList = false;
  searchConfigDataError = false;
  type = '';
  searchConfigName = '';
  searchConfigType = null;
  mounted() {
    this.type = this.$route.params.type || '';
    connectorMethodsService
      .list()
      .then((data: any) => {
        this.methods = data;
        this.searchConfigsOptions[1].options = {};
        data.map((item: any) => {
          this.searchConfigsOptions[1].options[item.id] = item.name;
        });
      })
      .catch(() => {});
    this.getSearchConfigList();
  }
  getSearchConfigList() {
    this.searchConfigDataError = false;
    this.isLoadingSearchList = true;
    searchConfigService
      .list()
      .then(data => {
        this.searchConfigs = data;
        this.isLoadingSearchList = false;
      })
      .catch(() => {
        this.searchConfigs = [];
        this.searchConfigDataError = true;
        this.isLoadingSearchList = false;
      });
  }
  saveSearchConfig() {
    searchConfigService
      .add(this.searchConfigName, this.searchConfigType)
      .then(() => {
        EventBus.$emit('show-success', 'Zapisano');
        this.searchConfigName = '';
        this.searchConfigType = null;
        this.getSearchConfigList();
      })
      .catch(() => {});
  }
  goTo(path: string) {
    this.type = path;
    if (path === '') {
      this.getSearchConfigList();
    }
    this.$router.push(`/search-config/${path}`).catch(() => {});
  }
}
