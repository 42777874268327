import { httpService } from '@/services/HttpService';

class SearchConfigService {
  update(id: number, name: string, fields: string, type: number): Promise<any> {
    return httpService.post(`searchConfiguration/${id}`, { name, fields, type }, {});
  }
  add(name: string, type: any): Promise<any> {
    return httpService.post(`searchConfiguration`, { name, type }, {});
  }
  list(): Promise<any> {
    return httpService.get('searchConfiguration', {}, {});
  }
}

export const searchConfigService = new SearchConfigService();
